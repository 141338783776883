
  .editor a {
      color: rgb(85, 152, 206);
      cursor: pointer;
  }
  .editor{ 
    width: 100%;
    font-size: 1.125rem;
    border-radius: 0.25rem;
    border: 0;
    border-color: inherit;
    background-color: #fff;
    padding-top: 8px;
    padding-bottom: 8px;
    min-height: 60px;
    line-height: 1.375;
  }
