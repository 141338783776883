/* common */
.Container {
  text-align: left;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  border: none;
  color: black;
  transition: 0.3s all ease;
  height: fit-content;
}
.Container:hover {
  background-color: #fafafa !important;
  cursor: pointer;
}
.Secondary {
  color: #646464;
}
.LowerContainer {
  padding: 10px;
  background-color: #fafafa ;
}
.Title {
  margin-top: 0;
  margin-bottom: 5px;
}
.Image {
  width: 100%;
  height: 100%;
  max-height: 90px;
  max-width: 90px; 
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.SiteDetails {
  margin-top: 10px;
}
/* sm */
@media (max-width: 640px) {
  .Description {
    display: none;
  }
}
/* md */
@media (min-width: 641px) and (max-width: 768px) {
  .Description {
    display: none;
  }
}
