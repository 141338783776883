.scrollableTable {
  overflow-x: auto;
}

.scrollableTableBody table {
  min-width: 800px; 
  width: 100%;
  border-collapse: collapse;
 
}

.scrollableTableBody tbody {
  display: block;
  max-height: 600px;
  overflow-y: auto;
  border-radius: 20px;
  margin: 0px 3px;
  box-shadow: 0px 0px 3px #d0d0d0
}

.scrollableTableBody thead {
  display: table;
  width: 100%;
  table-layout: fixed;
  background-color: 'transparent';
}
.scrollableTableBody tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.scrollableTable tr:not(:last-child) {
  border-bottom: 1px solid #ecedf5;
}

.scrollableTable.withHoverEffects .scrollableTableBody tbody tr:hover,
.scrollableTable.withHoverEffects .scrollableTableBody tbody tr:focus {
  color: inherit;
  text-decoration: none;
  transform: translateY(-4px);
  box-shadow: 0 2px 6px 0 #bfc0c9;
}