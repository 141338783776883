.datepicker .react-datepicker__day--hover {
  background-color: transparent !important; /* Убираем розовый эффект */
  border: 1px solid transparent !important; /* Убираем бордер, если есть */
}

.datepicker .react-datepicker__day--range-middle {
  background-color: #dadada !important; /* Светло-серый фон для дней в диапазоне */
  color: black;
  border-radius: 50% !important;

}

.datepicker .react-datepicker__day--range-start,
.datepicker .react-datepicker__day--range-end {
  color: white;
  border-radius: 50% !important;
}

.datepicker .react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
  color: inherit !important;
}
.datepicker .react-datepicker__day--in-selecting-range {
  background-color: #d3d3d3 !important;
  border-radius: 50% !important;
}
.datepicker :global(.react-datepicker) {
  box-shadow: none !important;
  border-radius: 0 !important;
}
.datepicker :global(.react-datepicker__header) {
  background-color: white !important;
  border-top-left-radius: none !important;
  border-top-right-radius: none !important
}
.datepicker :global(.react-datepicker__week) {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  justify-content: space-around !important;
}
.datepicker :global(.react-datepicker__day) {
  line-height: 45px !important;
  width: 45px !important;
  font-size: 15px !important;
}


